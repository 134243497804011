import { create } from 'zustand'

import { Candidate, CenterData, RecruitmentStudy, SorterOrder } from '../../../requests'
import { TableRecord } from '../../../utils'

interface CandidatesTableOptions {
  pageNumber: number
  search: string
  filters?: Record<string, string>
  sorter: {
    field: keyof Candidate
    order: SorterOrder
  }
}

interface RecruitmentStudyDetailsState {
  study: RecruitmentStudy
  reloadCandidatesTable: boolean
  reloadKpis: boolean
  candidatesTableOptions: CandidatesTableOptions
  centers: CenterData[]
  setStudy: (study: RecruitmentStudy) => void
  triggerReloadCandidatesTable: () => void
  triggerReloadKpis: () => void
  setCandidatesTableOptions: (options: CandidatesTableOptions) => void
  setCenters: (centers: CenterData[]) => void
  candidates: TableRecord<Candidate>[]
  setCandidates: (candidates: TableRecord<Candidate>[]) => void
  candidateToView: Candidate
  setCandidateToView: (candidate: Candidate) => void
  updateCandidate: (candidate: Candidate) => void
  filters: Record<string, string[]>
  setFilters: (filters: Record<string, string[]>) => void
  clearStudyDetails: () => void
}

const studyDetailsInitialState = {
  study: null as RecruitmentStudy,
  centers: [] as CenterData[],
  candidates: [] as TableRecord<Candidate>[],
  candidateToView: null as Candidate,
  filters: null as Record<string, string[]>,
  candidatesTableOptions: {
    pageNumber: 0,
    search: '',
    sorter: {
      field: 'firstName' as keyof Candidate,
      order: SorterOrder.Ascend
    }
  }
}

export const useRecruitmentStudyDetailsStore = create<RecruitmentStudyDetailsState>()(set => ({
  ...studyDetailsInitialState,
  setStudy: (study: RecruitmentStudy) => set({ study }),
  reloadCandidatesTable: false,
  reloadKpis: false,
  triggerReloadCandidatesTable: () =>
    set((state: RecruitmentStudyDetailsState) => ({
      reloadCandidatesTable: !state.reloadCandidatesTable,
      reloadKpis: !state.reloadKpis
    })),
  triggerReloadKpis: () =>
    set((state: RecruitmentStudyDetailsState) => ({
      reloadKpis: !state.reloadKpis
    })),
  setCandidatesTableOptions: (candidatesTableOptions: CandidatesTableOptions) => set({ candidatesTableOptions }),
  setCenters: (centers: CenterData[]) => set({ centers }),
  setCandidates: (candidates: TableRecord<Candidate>[]) => set({ candidates }),
  setCandidateToView: (candidate: Candidate) => set({ candidateToView: candidate }),
  updateCandidate: (candidate: Candidate) =>
    set((state: RecruitmentStudyDetailsState) => {
      const updatedCandidates = [...state.candidates]
      const candidateIndex = updatedCandidates.findIndex(c => c.id === candidate.id)
      updatedCandidates[candidateIndex] = { ...candidate, key: candidate.id }
      return { candidates: updatedCandidates }
    }),
  setFilters: (filters: Record<string, string[]>) => set({ filters }),
  clearStudyDetails: () => set(studyDetailsInitialState)
}))
