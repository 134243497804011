import getSymbolFromCurrency from 'currency-symbol-map'
import React from 'react'

import { useScopedIntl, useScopedIntlWithTags } from '../../../../hooks'
import { ExportFileFormat, PaymentOrder, bulkMakePayments, exportPaymentOrders } from '../../../../requests'
import { TableRecord, convertXMLStringToblob, downloadBlob } from '../../../../utils'
import { DatacMessage, DatacModal } from '../../../common'
import { usePaymentOrdersDashboardStore } from '../PaymentOrdersDashboardStore'

interface MakePaymentModalProps {
  isOpened: boolean
  onClose: () => void
}

export const MakePaymentModal: React.FC<MakePaymentModalProps> = ({ onClose, isOpened }) => {
  const intlModal = useScopedIntl('payment_orders.make_payments')
  const intlModalTags = useScopedIntlWithTags('payment_orders.make_payments')
  const intl = useScopedIntl('')

  const { paymentOrders, selectedPaymentOrders, isEverythingSelected, setIsMakingPayments } =
    usePaymentOrdersDashboardStore()

  const onAccept = () => {
    setIsMakingPayments(true)
    bulkMakePayments(
      {
        records: selectedPaymentOrders?.length && !isEverythingSelected ? selectedPaymentOrders : undefined
      },
      {
        onSuccess: (file, records) => {
          if (!file) {
            exportPaymentOrders(
              {
                exportFileFormat: ExportFileFormat.Csv,
                exportFiles: false,
                search: '',
                records,
                status: []
              },
              {
                onSuccess: () => {
                  setIsMakingPayments(false)
                  onClose()
                },
                onRequestError: code => {
                  setIsMakingPayments(false)
                  DatacMessage.genericError(intl, code)
                }
              }
            )
            return
          }
          onClose()
          setIsMakingPayments(false)
          downloadBlob(convertXMLStringToblob(file.data), file.filename)
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsMakingPayments(false)
        },
        onCenterDataMissing: () => {
          DatacMessage.error(intlModal('error.title'), intlModal('center_data_missing.description'))
          setIsMakingPayments(false)
        },
        onCurrencyMismatch: () => {
          DatacMessage.error(intlModal('error.title'), intlModal('wrong_center_currency.description'))
          setIsMakingPayments(false)
        }
      }
    )
  }

  const selectedPaymentOrdersObjects: TableRecord<PaymentOrder>[] = paymentOrders.filter(
    (paymentOrder: TableRecord<PaymentOrder>) => selectedPaymentOrders.includes(paymentOrder.id)
  )
  const count = selectedPaymentOrders.length
  const amount = selectedPaymentOrdersObjects.reduce((acc: number, paymentOrder: TableRecord<PaymentOrder>) => {
    return acc + paymentOrder.amount
  }, 0.0)
  const currency = getSymbolFromCurrency(selectedPaymentOrdersObjects[0]?.currency)

  return (
    <DatacModal title={intlModal('title')} isOpened={isOpened} onClose={onClose} onSubmit={onAccept}>
      <div className="payment-order-make-payments-modal">
        {intlModalTags('content', {
          count: `<b>${count}</b>`,
          amount: `<b>${amount}</b>`,
          currency: `<b>${currency}</b>`
        })}
      </div>
    </DatacModal>
  )
}
