import getSymbolFromCurrency from 'currency-symbol-map'
import { Dayjs } from 'dayjs'
import React from 'react'

import { PaymentOrder, PaymentOrderStatus } from '../../../requests'
import { numberWithSpaces } from '../../../utils'
import {
  ColumnFixedType,
  DatacDropdownMenu,
  DatacDropdownMenuOptions,
  DatacIconName,
  DatacOption,
  DatacTableSearchAndFilterOptions,
  scopedIntlType
} from '../../common'
import { PaymentOrderStatusSelect } from '../PaymentOrderStatusSelect'

enum PaymentOrdersActionType {
  View = 'view',
  SetError = 'setError',
  Export = 'export'
}

export const ActionCell: React.VFC<{
  onSetError: () => void
  onExport: () => void
  onView: () => void
  paymentOrder: PaymentOrder
  canEdit: boolean
  canExport: boolean
}> = ({ onSetError, onExport, onView, paymentOrder, canEdit, canExport }) => {
  const onClick = (item: string) => {
    switch (item) {
      case PaymentOrdersActionType.View:
        onView()
        break
      case PaymentOrdersActionType.SetError:
        onSetError()
        break
      case PaymentOrdersActionType.Export:
        onExport()
        break
      default:
        break
    }
  }

  const menuOptions: DatacDropdownMenuOptions<PaymentOrdersActionType> = [
    /* TODO: enable when quickview is done 
    {
      type: PaymentOrdersActionType.View,
      label: 'common.view',
      icon: 'eye'
    }, */
    ...(canExport
      ? [
          {
            type: PaymentOrdersActionType.Export,
            label: 'common.export',
            icon: 'download'
          }
        ]
      : []),
    ...(canEdit && paymentOrder.status === PaymentOrderStatus.Paid
      ? [
          {
            type: PaymentOrdersActionType.SetError,
            label: 'payment_orders.set_error',
            icon: 'xCircle'
          }
        ]
      : [])
  ]

  return (
    <div className="payment-orders-dashboard__table__row-actions">
      <DatacDropdownMenu options={menuOptions} onClick={item => onClick(item)} />
    </div>
  )
}

const FullNameCell: React.VFC<{ paymentOrder: PaymentOrder }> = ({ paymentOrder }) => {
  return (
    <div className="payment-orders-dashboard__table__name">
      <div className="payment-orders-dashboard__table__name__full-name">{paymentOrder.fullName}</div>
      <div className="payment-orders-dashboard__table__name__id">{paymentOrder.datacaptId}</div>
    </div>
  )
}

const VisitCell: React.VFC<{ paymentOrder: PaymentOrder }> = ({ paymentOrder }) => {
  return (
    <div className="payment-orders-dashboard__table__name">
      {paymentOrder.visitName && (
        <div className="payment-orders-dashboard__table__name__full-name">{paymentOrder.visitName}</div>
      )}
      {paymentOrder.scheduleName && (
        <div className="payment-orders-dashboard__table__name__id">{paymentOrder.scheduleName}</div>
      )}
    </div>
  )
}

const StatusCell: React.VFC<{ status: PaymentOrderStatus; statusDetails?: string }> = ({ status, statusDetails }) => {
  return status && <PaymentOrderStatusSelect status={status} statusDetails={statusDetails} />
}

const AmountCell: React.VFC<{
  amount: number
  currency: string
  onView: () => void
}> = ({ amount, currency }) => {
  const symbol = getSymbolFromCurrency(currency)
  return (
    <div className="payment-orders-dashboard__table__amount">
      <strong>
        {numberWithSpaces(amount)} {symbol}
      </strong>
    </div>
  )
}

interface GetColumnsOptions {
  columnNames: {
    fullName: string
    recruitmentReference: string
    creationDate: string
    center: string
    visit: string
    amount: string
    status: string
    statusDetails: string
    paymentDate: string
    transactionId: string
    actions: string
  }
  onView: (paymentOrder: PaymentOrder) => void
  onSetError: (paymentOrder: PaymentOrder) => void
  onExport: (paymentOrder: PaymentOrder) => void
  canEdit: boolean
  canExport: boolean
}

export const getColumns = ({ columnNames, onView, onSetError, onExport, canEdit, canExport }: GetColumnsOptions) => {
  return [
    {
      title: columnNames.fullName,
      dataIndex: 'fullName',
      key: 'fullName',
      width: '24rem',
      ellipsis: true,
      fixed: 'left' as ColumnFixedType,
      sorter: true,
      render: (_: string, paymentOrder: PaymentOrder) => <FullNameCell paymentOrder={paymentOrder} />
    },
    {
      title: columnNames.recruitmentReference,
      dataIndex: 'recruitmentReference',
      key: 'recruitmentReference',
      width: '20rem',
      sorter: true,
      ellipsis: true,
      render: (recruitmentReference: string) => recruitmentReference
    },
    {
      title: columnNames.creationDate,
      dataIndex: 'creationDate',
      key: 'creationDate',
      width: '16rem',
      sorter: true,
      ellipsis: true,
      render: (creationDate: Dayjs) => (creationDate ? creationDate.format('DD MMM YYYY') : '')
    },
    {
      title: columnNames.center,
      dataIndex: 'center',
      key: 'center',
      width: '20rem',
      sorter: true,
      ellipsis: true,
      render: (center: string) => center
    },
    {
      title: columnNames.visit,
      dataIndex: 'visit',
      key: 'visit',
      width: '20rem',
      sorter: true,
      ellipsis: true,
      render: (_: string, paymentOrder: PaymentOrder) => <VisitCell paymentOrder={paymentOrder} />
    },
    {
      title: columnNames.amount,
      dataIndex: 'amount',
      key: 'amount',
      width: '12rem',
      ellipsis: true,
      sorter: true,
      render: (_: string, paymentOrder: PaymentOrder) => (
        <AmountCell amount={paymentOrder.amount} currency={paymentOrder.currency} onView={() => onView(paymentOrder)} />
      )
    },
    {
      title: columnNames.status,
      dataIndex: 'status',
      key: 'status',
      width: '20rem',
      sorter: true,
      render: (_: string, paymentOrder: PaymentOrder) => (
        <StatusCell status={paymentOrder.status} statusDetails={paymentOrder.statusDetails} />
      )
    },
    {
      title: columnNames.paymentDate,
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      width: '16rem',
      sorter: true,
      ellipsis: true,
      render: (paymentDate: Dayjs) => (paymentDate ? paymentDate.format('DD MMM YYYY') : '')
    },
    {
      title: columnNames.transactionId,
      dataIndex: 'transactionId',
      key: 'transactionId',
      width: '20rem',
      sorter: true,
      ellipsis: true,
      render: (transactionId: string) => transactionId
    },
    {
      title: columnNames.actions,
      dataIndex: '',
      key: 'actions',
      render: (_: string, paymentOrder: PaymentOrder) => (
        <ActionCell
          onSetError={() => {
            onSetError(paymentOrder)
          }}
          onExport={() => {
            onExport(paymentOrder)
          }}
          onView={() => {
            onView(paymentOrder)
          }}
          paymentOrder={paymentOrder}
          canEdit={canEdit}
          canExport={canExport}
        />
      ),
      width: '8rem',
      fixed: 'right' as ColumnFixedType
    }
  ]
}

export const searchAndFilterOptions = (
  centersOptions: DatacOption[],
  studiesOptions: DatacOption[],
  visitsOptions: DatacOption[],
  searchCenters: (search: string) => void,
  searchStudies: (search: string) => void,
  searchVisits: (search: string) => void,
  intl: scopedIntlType
) => {
  return {
    searchInputPlaceholder: intl('payment_orders.search.placeholder'),
    filters: [
      {
        label: intl('payment_orders.field.status'),
        name: 'status',
        options: (Object.values(PaymentOrderStatus) as Array<PaymentOrderStatus>).map(status => ({
          value: status,
          label: intl(`status.${status.toLowerCase()}`)
        })),
        main: true,
        icon: 'checkCircle' as DatacIconName
      },
      {
        label: intl('payment_orders.field.creation_date'),
        name: 'creation_date',
        main: true,
        datePicker: true
      },
      {
        label: intl('payment_orders.field.payment_date'),
        name: 'payment_date',
        main: true,
        datePicker: true
      },
      {
        label: intl('payment_orders.field.recruitment_reference'),
        name: 'recruitment_reference',
        options: studiesOptions,
        handleSearch: searchStudies,
        fetchOnOpen: true
      },
      {
        label: intl('payment_orders.field.visit'),
        name: 'visit',
        options: visitsOptions,
        handleSearch: searchVisits,
        fetchOnOpen: true
      },
      {
        label: intl('payment_orders.field.center_abbreviation'),
        name: 'center',
        options: centersOptions,
        handleSearch: searchCenters,
        fetchOnOpen: true
      }
    ]
  } as DatacTableSearchAndFilterOptions
}
